<template>
  <div>
    <div>
      <div class="col-md-7 pr-0" id="allOpenCont">
        <div class="nameAndImg">
          <div class="imgName">
            <div class="image_div position-relative mx-auto">
              <img
                :src="getImg(user.photo)"
                class="img_user"
                @error="errorImage"
              />
            </div>
          </div>
          <div class="name">
            <p class="title">
              Olá,
              <br />
              <strong>{{ user.displayName }}!</strong>
            </p>
          </div>
        </div>
        <p class="texto mb-0">
          Obrigado pelo trabalho!<img
            src="../../assets/image/iconesMobile/emojiLogout.svg"
            width="auto"
            height="20px"
          />
        </p>
        <br />
        <p class="texto mb-0">
          Existe um caixa aberto do dia anterior, para prosseguir
          <strong>feche-o.</strong>
        </p>
      </div>
      <div class="closeCont">
        <h2 class="fonts">
          {{
            !day
              ? "Fechar um caixa ativo e iniciar um novo."
              : "Já existe um caixa aberto em operação, deseja encerra-lo ou prosseguir com suas vendas?"
          }}
        </h2>

        <div class="divBtnCont">
          <button type="button" class="btn continue" @click="closeCaixa">
            Fechar Caixa
          </button>
          <button
            type="button"
            class="btn default"
            @click="dashboard"
            v-if="day"
          >
            Continuar minhas vendas
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      virifyBool: false,
      id: null,
      day: false,
    };
  },
  methods: {
    closeCaixa() {
      

      // this.$store.commit("SET_CAIXAID", this.idCaixa);
      this.$store.commit("SET_VALIDATION", true);
      this.$nextTick(() => {
        this.$router.push("/logout");
      });
    },
    dashboard() {
      $("#modalOpen").modal("hide");

      this.$notify({
        group: "erros",
        type: "sucess",
        text: `<i class="icon ion-close-circled"></i>Seja bem vindo(a) ${this.$store.getters.getUser.displayName}`,
      });

      // this.$store.commit("SET_CAIXAID", this.idCaixa);
      this.$store.commit("SET_VALIDATION", true);
      this.$nextTick(() => {
        this.$router.push("/");
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    },
  },
};
</script>


<style scoped>
.image_div {
  overflow: hidden;
  width: 85px;
  overflow: hidden;
  height: 85px;
  border-radius: 50%;
  border: 5px solid #2474b2;
  margin-bottom: 4%;
}
.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.nameAndImg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4%;
}
.name {
  display: flex;
  text-align: initial;
  color: #f38235;
  margin-left: 10px;
}
.name p {
  font-size: 17px;
}
.texto {
  color: #868686;
  font-size: 14px;
}
#allOpenCont {
  height: 200px;
}
.closeCont {
  background: #e9e9eb;
  border: 2px solid #e9e9eb;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 135px;
}
.divBtnCont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.btn {
  background: #f65757;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 22px;
  gap: 10px;
  color: white;
  width: 70%;
}
h2 {
  width: 60%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #797984;
}
</style>
